import axios, { Method, AxiosInstance, AxiosError, AxiosRequestConfig, AxiosAdapter } from 'axios';
import { format as _formatAxiosError } from '@redtea/format-axios-error';

import { RequestOptions, RoseApi } from './apiTypes';
import {
  collectorUrl,
  getR4cApiUrl,
  metricsUrl,
  r4cDispatchUrl,
  getSaverUrl,
  getDoctosyncUrl,
  getUiUrl,
} from '../config';
import { HEADERS } from '../../../types';
import { sendCommandToUi } from '../helpers/uiCommunication';
import { RequestError } from '../types/errors';

export type AxiosErrorExport = AxiosError;

const defaultAdapter = axios.defaults.adapter;
const timingAdapter: AxiosAdapter = (config: AxiosRequestConfig) =>
  new Promise((resolve, reject) => {
    if (!defaultAdapter) {
      throw new Error('No default adapter found');
    }

    const startTime = Date.now();

    sendCommandToUi(
      {
        command: 'requestStarted',
      },
      { skipLog: true },
    );
    const submitRequestTime = (duration: number) => {
      sendCommandToUi(
        {
          command: 'requestTiming',
          duration,
        },
        { skipLog: true },
      );
      sendCommandToUi(
        {
          command: 'requestEnded',
        },
        { skipLog: true },
      );
    };

    defaultAdapter(config)
      .then(response => {
        const duration = Date.now() - startTime;
        submitRequestTime(duration);

        resolve(response);
      })
      .catch(error => {
        const duration = Date.now() - startTime;
        submitRequestTime(duration);

        reject(error);
      });
  });
axios.defaults.adapter = timingAdapter;

export function truncateString(str: any, maxLength: number): string {
  if (str === null || str === undefined) {
    return '';
  }
  if (typeof str !== 'string') {
    str = String(str);
  }

  if (str.length > maxLength) {
    return str.substring(0, maxLength) + '...';
  }
  return str;
}

export function formatApiErrorJson(err: any) {
  if (axios.isAxiosError(err)) {
    const axiosErr = _formatAxiosError(err);

    // get auth header, extract jwt and add it
    const authHeader: string = axiosErr.config.headers.Authorization;
    const jwtToken = authHeader?.split(' ')[1];
    let jwt: any;
    if (jwtToken) {
      try {
        jwt = extractPayloadFromToken(jwtToken);
      } catch (e) {
        console.error('Could not extract jwt from auth header', e);
      }
    }

    // truncate all headers
    for (const key of Object.keys(axiosErr.config.headers)) {
      axiosErr.config.headers[key] = truncateString(axiosErr.config.headers[key], 50);
    }

    return { ...axiosErr, jwt };
  }

  return String(err);
}

export function formatApiError(err: any) {
  return JSON.stringify(formatApiErrorJson(err));
}

export function extractPayloadFromToken(token: string) {
  const base64Url: string = token.split('.')[1];
  const base64: string = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(''),
  );

  return JSON.parse(jsonPayload);
}
export function extractRequestIdFromError(err: any) {
  if (axios.isAxiosError(err)) {
    return err.response?.headers[HEADERS.ROSEREQUESTID];
  }
}

export function formatApiErrorForUser(err: any, truncateLength = 100): string {
  if (axios.isAxiosError(err)) {
    const parts = [];

    if (err.response?.headers[HEADERS.ROSEREQUESTID]) {
      parts.push(`rid: ${err.response?.headers[HEADERS.ROSEREQUESTID]}`);
    }

    let errMsg = err.message;
    // convert "Request failed with status code 500" to short version "Code 500"
    errMsg = errMsg.replace(/Request failed with status code (\d+)/, 'Code $1');
    parts.push(errMsg);

    if (err.response?.data?.error) {
      parts.push(truncateString(err.response?.data?.message || err.response?.data?.error, truncateLength));
    }

    if (!err.response) {
      parts.push('Keine Antwort vom Server erhalten');
    }

    return parts.join(' | ');
  } else if (err instanceof RequestError) {
    return truncateString(err.data?.message || err.message, truncateLength);
  }

  return truncateString(String(err), truncateLength);
}

export function createStaticRoseApiBackends() {
  const metricsBackend = axios.create({
    baseURL: metricsUrl,
  });

  const collectorBackend = axios.create({
    baseURL: collectorUrl,
  });

  const r4cDispatcherBackend = axios.create({
    baseURL: r4cDispatchUrl,
  });

  const saverBackend = axios.create({
    baseURL: getSaverUrl(),
  });

  const doctosyncBackend = axios.create({
    baseURL: getDoctosyncUrl(),
  });

  const uiBackend = axios.create({
    baseURL: getUiUrl(),
  });

  return {
    metricsBackend,
    collectorBackend,
    r4cDispatcherBackend,
    saverBackend,
    doctosyncBackend,
    uiBackend,
  };
}

const r4cBackends: { [r4chost: string]: AxiosInstance } = {};
export function createR4cBackend(r4chost: string) {
  if (!r4cBackends[r4chost]) {
    r4cBackends[r4chost] = axios.create({
      baseURL: getR4cApiUrl(r4chost),
    });
  }
  return r4cBackends[r4chost];
}

export type AuthRequestStateGetter = () => {
  authToken?: string | null;
  cid?: string | null;
  r4cCid?: string | null;
  taskId?: string | null;
  additionalHeaders?: { [key: string]: string | undefined };
  r4chost?: string | null;
};

export function createAuthRequestWithStateGetter(stateGetter: AuthRequestStateGetter) {
  // create an authenticated request
  function authRequest(
    backend: AxiosInstance,
    url: string,
    method: Method = 'get',
    { raw, clientIdHeader, data = null, query, headers, rid, responseType }: RequestOptions = {},
  ): any {
    // eslint-disable-next-line prefer-const
    let { authToken, cid, taskId, additionalHeaders } = stateGetter();

    // if clientIdHeader is set override cid
    if (clientIdHeader) {
      cid = clientIdHeader;
    }

    return backend
      .request({
        url,
        method,
        data,
        params: query,
        responseType,
        headers: {
          Authorization: `Bearer ${authToken}`,
          Accept: 'application/json',
          ...(cid ? { [HEADERS.ROSECLIENTID]: cid } : {}),
          ...(taskId ? { [HEADERS.ROSETASKID]: taskId } : {}),
          ...(rid ? { [HEADERS.ROSEREQUESTID]: rid } : {}),
          ...(additionalHeaders || {}),
          ...(headers || {}),
        },
      })
      .then(res => {
        if (raw) {
          return res;
        } else {
          return res.data;
        }
      })
      .catch(async (error: AxiosError) => {
        // for blob responses, try to parse the error response data as JSON and replace the response data with the parsed JSON
        if (error.response && error.response.data instanceof Blob) {
          try {
            // Convert the Blob to JSON
            const text = await error.response.data.text();
            const json = JSON.parse(text);

            // Replace the error response data with the parsed JSON
            error.response.data = json;
          } catch (e) {
            // noop
            console.warn('Error parsing error response data as JSON', e, error);
          }
        }

        throw error;
      });
  }

  return authRequest;
}

export function createRoseApiWithAxios(stateGetter: AuthRequestStateGetter) {
  // create backends
  const { metricsBackend, collectorBackend, r4cDispatcherBackend, saverBackend, doctosyncBackend, uiBackend } =
    createStaticRoseApiBackends();

  // create authRequest method
  const authRequest = createAuthRequestWithStateGetter(stateGetter);

  // create roseApi
  const roseApi: RoseApi = {
    metrics: {
      get: <T = any>(url: string, opts?: RequestOptions): Promise<T> => authRequest(metricsBackend, url, 'get', opts),
      put: <T = any>(url: string, data?: any, opts?: RequestOptions): Promise<T> =>
        authRequest(metricsBackend, url, 'put', { data, ...opts }),
      post: <T = any>(url: string, data?: any, opts?: RequestOptions): Promise<T> =>
        authRequest(metricsBackend, url, 'post', { data, ...opts }),
      patch: <T = any>(url: string, data?: any, opts?: RequestOptions): Promise<T> =>
        authRequest(metricsBackend, url, 'patch', { data, ...opts }),
      delete: <T = any>(url: string, data?: any, opts?: RequestOptions): Promise<T> =>
        authRequest(metricsBackend, url, 'delete', { data, ...opts }),
    },
    r4c: {
      get: <T = any>(r4chost: string, url: string, opts?: RequestOptions): Promise<T> =>
        authRequest(createR4cBackend(r4chost), url, 'get', opts),
      put: <T = any>(r4chost: string, url: string, data?: any, opts?: RequestOptions): Promise<T> =>
        authRequest(createR4cBackend(r4chost), url, 'put', { data, ...opts }),
      post: <T = any>(r4chost: string, url: string, data?: any, opts?: RequestOptions): Promise<T> =>
        authRequest(createR4cBackend(r4chost), url, 'post', { data, ...opts }),
      patch: <T = any>(r4chost: string, url: string, data?: any, opts?: RequestOptions): Promise<T> =>
        authRequest(createR4cBackend(r4chost), url, 'patch', { data, ...opts }),
      delete: <T = any>(r4chost: string, url: string, data?: any, opts?: RequestOptions): Promise<T> =>
        authRequest(createR4cBackend(r4chost), url, 'delete', { data, ...opts }),
    },
    r4cInstance: {
      get: <T = any>(url: string, opts?: RequestOptions): Promise<T> => {
        const { r4chost } = stateGetter();
        if (!r4chost) {
          throw new Error(`using r4cInstance api without r4chost set in stateGetter`);
        }
        return authRequest(createR4cBackend(r4chost), url, 'get', opts);
      },
      put: <T = any>(url: string, data?: any, opts?: RequestOptions): Promise<T> => {
        const { r4chost } = stateGetter();
        if (!r4chost) {
          throw new Error(`using r4cInstance api without r4chost set in stateGetter`);
        }
        return authRequest(createR4cBackend(r4chost), url, 'put', { data, ...opts });
      },
      post: <T = any>(url: string, data?: any, opts?: RequestOptions): Promise<T> => {
        const { r4chost } = stateGetter();
        if (!r4chost) {
          throw new Error(`using r4cInstance api without r4chost set in stateGetter`);
        }
        return authRequest(createR4cBackend(r4chost), url, 'post', { data, ...opts });
      },
      patch: <T = any>(url: string, data?: any, opts?: RequestOptions): Promise<T> => {
        const { r4chost } = stateGetter();
        if (!r4chost) {
          throw new Error(`using r4cInstance api without r4chost set in stateGetter`);
        }
        return authRequest(createR4cBackend(r4chost), url, 'patch', { data, ...opts });
      },
      delete: <T = any>(url: string, data?: any, opts?: RequestOptions): Promise<T> => {
        const { r4chost } = stateGetter();
        if (!r4chost) {
          throw new Error(`using r4cInstance api without r4chost set in stateGetter`);
        }
        return authRequest(createR4cBackend(r4chost), url, 'delete', { data, ...opts });
      },
    },
    r4cDispatch: {
      get: <T = any>(url: string, opts?: RequestOptions): Promise<T> =>
        authRequest(r4cDispatcherBackend, url, 'get', opts),
      put: <T = any>(url: string, data?: any, opts?: RequestOptions): Promise<T> =>
        authRequest(r4cDispatcherBackend, url, 'put', { data, ...opts }),
      post: <T = any>(url: string, data?: any, opts?: RequestOptions): Promise<T> =>
        authRequest(r4cDispatcherBackend, url, 'post', { data, ...opts }),
      patch: <T = any>(url: string, data?: any, opts?: RequestOptions): Promise<T> =>
        authRequest(r4cDispatcherBackend, url, 'patch', { data, ...opts }),
      delete: <T = any>(url: string, data?: any, opts?: RequestOptions): Promise<T> =>
        authRequest(r4cDispatcherBackend, url, 'delete', { data, ...opts }),
    },
    collector: {
      get: <T = any>(url: string, opts?: RequestOptions) => authRequest(collectorBackend, url, 'get', opts),
      put: <T = any>(url: string, data?: any, opts?: RequestOptions): Promise<T> =>
        authRequest(collectorBackend, url, 'put', { data, ...opts }),
      post: <T = any>(url: string, data?: any, opts?: RequestOptions): Promise<T> =>
        authRequest(collectorBackend, url, 'post', { data, ...opts }),
      patch: <T = any>(url: string, data?: any, opts?: RequestOptions): Promise<T> =>
        authRequest(collectorBackend, url, 'patch', { data, ...opts }),
      delete: <T = any>(url: string, data?: any, opts?: RequestOptions): Promise<T> =>
        authRequest(collectorBackend, url, 'delete', { data, ...opts }),
    },
    saver: {
      get: <T = any>(url: string, opts?: RequestOptions): Promise<T> => authRequest(saverBackend, url, 'get', opts),
      put: <T = any>(url: string, data?: any, opts?: RequestOptions): Promise<T> =>
        authRequest(saverBackend, url, 'put', { data, ...opts }),
      post: <T = any>(url: string, data?: any, opts?: RequestOptions): Promise<T> =>
        authRequest(saverBackend, url, 'post', { data, ...opts }),
      patch: <T = any>(url: string, data?: any, opts?: RequestOptions): Promise<T> =>
        authRequest(saverBackend, url, 'patch', { data, ...opts }),
      delete: <T = any>(url: string, data?: any, opts?: RequestOptions): Promise<T> =>
        authRequest(saverBackend, url, 'delete', { data, ...opts }),
    },
    doctosync: {
      get: <T = any>(url: string, opts?: RequestOptions): Promise<T> => authRequest(doctosyncBackend, url, 'get', opts),
      put: <T = any>(url: string, data?: any, opts?: RequestOptions): Promise<T> =>
        authRequest(doctosyncBackend, url, 'put', { data, ...opts }),
      post: <T = any>(url: string, data?: any, opts?: RequestOptions): Promise<T> =>
        authRequest(doctosyncBackend, url, 'post', { data, ...opts }),
      patch: <T = any>(url: string, data?: any, opts?: RequestOptions): Promise<T> =>
        authRequest(doctosyncBackend, url, 'patch', { data, ...opts }),
      delete: <T = any>(url: string, data?: any, opts?: RequestOptions): Promise<T> =>
        authRequest(doctosyncBackend, url, 'delete', { data, ...opts }),
    },
    ui: {
      get: <T = any>(url: string, opts?: RequestOptions): Promise<T> => authRequest(uiBackend, url, 'get', opts),
      put: <T = any>(url: string, data?: any, opts?: RequestOptions): Promise<T> =>
        authRequest(uiBackend, url, 'put', { data, ...opts }),
      post: <T = any>(url: string, data?: any, opts?: RequestOptions): Promise<T> =>
        authRequest(uiBackend, url, 'post', { data, ...opts }),
      patch: <T = any>(url: string, data?: any, opts?: RequestOptions): Promise<T> =>
        authRequest(uiBackend, url, 'patch', { data, ...opts }),
      delete: <T = any>(url: string, data?: any, opts?: RequestOptions): Promise<T> =>
        authRequest(uiBackend, url, 'delete', { data, ...opts }),
    },
  };

  return {
    roseApi,
    backends: { metricsBackend, collectorBackend },
  };
}
