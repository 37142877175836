import {
  IAllParams,
  IBehandler,
  IBehandlerTerminGruppe,
  IBemaGozStammdaten,
  IStichwortParam,
  ITeam,
  ITeamQueryOptions,
  ITerminart,
  IZimmer,
  IPraxisStempel,
} from '../../../../types';
import { RoseApi } from '../apiTypes';

export function createStaticDataApi(api: RoseApi) {
  return {
    getBehandler: (all?: boolean) => api.metrics.get<IBehandler[]>(`/api/staticdata/behandler`, { query: { all } }),
    getBemaGoz: () => api.metrics.get<IBemaGozStammdaten[]>(`/api/staticdata/bemagoz`, {}),
    getTerminarten: () => api.metrics.get<ITerminart[]>(`/api/staticdata/terminarten`, {}),
    getTermingruppen: () => api.metrics.get<IBehandlerTerminGruppe[]>(`/api/staticdata/termingruppen`, {}),
    getZimmer: () => api.metrics.get<IZimmer[]>(`/api/staticdata/zimmer`, {}),
    getTeams: (options: ITeamQueryOptions) =>
      api.metrics.get<ITeam[]>(`/api/staticdata/teams`, {
        query: options as any,
      }),
    getStichworte: () => api.metrics.get<IStichwortParam[]>(`/api/staticdata/stichworte`, {}),
    getTopStichworte: (limit?: number) =>
      api.metrics.get<string[]>(`/api/staticdata/stichworte/top`, { query: { limit } }),
    getParams: () => api.metrics.get<IAllParams>(`/api/staticdata/params`, {}),
    getStempel: (clientIdOverride?: string) =>
      api.metrics.get<IPraxisStempel[]>(`/api/staticdata/stempel`, { clientIdHeader: clientIdOverride }),
  };
}
