export class RequestError extends Error {
  constructor(
    message: string,
    public url: string,
    public statusCode: number,
    public data: any,
    public logged: boolean,
  ) {
    super(message);
    this.name = 'RequestError';
    this.stack = new Error().stack;
  }
}
