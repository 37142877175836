import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.flat-map.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.unscopables.flat-map.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.test.js";
import "core-js/modules/es.string.repeat.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.find.js";
import "core-js/modules/esnext.iterator.flat-map.js";
import "core-js/modules/esnext.iterator.for-each.js";
import "core-js/modules/esnext.iterator.map.js";
import "core-js/modules/esnext.iterator.some.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
import { defineComponent } from 'vue';
import { onboardingStore, createR4cPayload, changeInfo as _changeInfo } from '@/state/onboardingStore';
import { dzrOrgUnitResolve } from '../../../base';
import { isProd, isDev, tzFilter } from '../../../common-ui';
import { FEATURES, R4CAPP } from '../../../types';
import { StepId } from '@/state/tasks';
import GutscheinInfo from './GutscheinInfo.vue';
import CheckEditButton from './CheckEditButton.vue';
import { chain, filter, find } from 'lodash';
import { paketeR4c } from '@/state/pakete';
export default defineComponent({
  components: {
    GutscheinInfo: GutscheinInfo,
    CheckEditButton: CheckEditButton
  },
  filters: {
    date: tzFilter.filters.tz
  },
  data: function data() {
    return {
      R4CAPP: R4CAPP,
      FEATURES: FEATURES,
      StepId: StepId,
      onboardingStore: onboardingStore,
      fullState: onboardingStore.state,
      kommentar: null,
      aktionscode: null,
      aktionscodeInfo: null,
      dzrOrgUnitResolve: dzrOrgUnitResolve,
      isDev: isDev,
      isProd: isProd
    };
  },
  computed: {
    paketeR4cToShow: function paketeR4cToShow() {
      return filter(paketeR4c, function (app) {
        return !isProd || app.live;
      });
    },
    changeInfo: function changeInfo() {
      var ci = _changeInfo();
      console.log('changeinfo', ci);
      return ci;
    },
    additionalRzLicenceRequired: function additionalRzLicenceRequired() {
      var currenLicences = chain(onboardingStore.state.activeRechenzentren).map(function (rz) {
        return rz.rzkey;
      }).uniq().size().value();
      // remove rzs that are going to be deleted
      var newState = onboardingStore.state.activeRechenzentren.filter(function (rz) {
        return !onboardingStore.state.rechenzentrenToDelete.find(function (rzToDelete) {
          return rz.id === rzToDelete.id;
        });
      });
      // add new rz
      Object.keys(onboardingStore.state.rechenzentrenData).forEach(function (rzKey) {
        if (onboardingStore.state.rechenzentrenData[rzKey]) {
          newState.push({
            rzkey: rzKey
          });
        }
      });
      var requiredLicenceCount = chain(newState).map(function (rz) {
        return rz.rzkey;
      }).uniq().size().value();
      return requiredLicenceCount > currenLicences && requiredLicenceCount > 1;
    },
    flowContainsPurchase: function flowContainsPurchase() {
      return this.stepIsInFlow(StepId.paket) || this.stepIsInFlow(StepId.r4cPaketAuswahl) || this.additionalRzLicenceRequired;
    },
    requestPending: function requestPending() {
      return onboardingStore.state.requestPending;
    },
    gesamtPreis: function gesamtPreis() {
      return onboardingStore.getters.gesamtPreis;
    },
    rechenzentrenData: function rechenzentrenData() {
      return onboardingStore.state.rechenzentrenData;
    },
    rechenzentrenToDelete: function rechenzentrenToDelete() {
      return onboardingStore.state.rechenzentrenToDelete;
    },
    gutscheinInfo: function gutscheinInfo() {
      return onboardingStore.state.gutscheinInfo;
    },
    rzGutscheinInfo: function rzGutscheinInfo() {
      return onboardingStore.state.gutscheinInfo.test || onboardingStore.state.gutscheinInfo.demo || onboardingStore.state.gutscheinInfo.bfs || onboardingStore.state.gutscheinInfo.dzr || onboardingStore.state.gutscheinInfo.abz || onboardingStore.state.gutscheinInfo.za || onboardingStore.state.gutscheinInfo.health || onboardingStore.state.gutscheinInfo.pvsdental || onboardingStore.state.gutscheinInfo.pvsreiss || onboardingStore.state.gutscheinInfo.arc || onboardingStore.state.gutscheinInfo.teamfaktor;
    },
    usedRoseAccount: function usedRoseAccount() {
      return onboardingStore.getters.usedRoseAccount;
    },
    selectedAtleastOneRz: function selectedAtleastOneRz() {
      return chain(onboardingStore.state.rechenzentrenData).values().some(function (d) {
        return !!d;
      }).value();
    }
  },
  watch: {
    aktionscode: {
      handler: function handler() {
        var solutiocodes = ['sales01', 'sales02', 'sales03', 'sales04', 'sales05', 'sales06', 'sales07', 'sales08', 'coaching01', 'coaching02', 'coaching03', 'coaching04', 'coaching05', 'coaching06', 'coaching07', 'coaching08', 'vid01'];
        if (this.aktionscode && solutiocodes.includes(this.aktionscode)) {
          this.aktionscodeInfo = "solutio Aktionscode erkannt. 1 Monat kostenlose Nutzung aktiviert.";
        } else {
          this.aktionscodeInfo = null;
        }
      },
      deep: true
    }
  },
  mounted: function mounted() {
    // view cannot be invalid
    this.$emit('viewValidUpdate', true);
    console.log("state", this.fullState);
    console.log('r4cpayload', createR4cPayload(onboardingStore.state));
  },
  methods: {
    buchenClicked: function buchenClicked() {
      console.log('r4cpayload', createR4cPayload(onboardingStore.state));
      onboardingStore.commit.setKommentar(this.kommentar);
      onboardingStore.commit.setAktionscode(this.aktionscode);
      onboardingStore.dispatch.buchen();
    },
    stepIsInFlow: function stepIsInFlow(stepId) {
      return !!find(onboardingStore.getters.shownStepsFlow, function (step) {
        return step.stepId === stepId;
      });
    },
    obfuscatePassword: function obfuscatePassword(pw) {
      return pw.charAt(0) + '*'.repeat(pw.length - 1);
    },
    getChoiceInfo: function getChoiceInfo(featureId) {
      var ai = chain(paketeR4c).flatMap(function (app) {
        return app.choices;
      }).find(function (c) {
        return c.feature === featureId;
      }).value();
      return ai;
    }
  }
});