import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c(VRow, {
    attrs: {
      "justify": "center"
    }
  }, [_c(VCol, {
    attrs: {
      "cols": "12",
      "sm": "10",
      "md": "8",
      "lg": "6"
    }
  }, [_c(VCard, [_c(VCardText, [_c(VForm, {
    model: {
      value: _vm.formValid,
      callback: function callback($$v) {
        _vm.formValid = $$v;
      },
      expression: "formValid"
    }
  }, [!_vm.isR4cOnboarding ? _c('div', [_c('h3', [_vm._v("PVS (Praxis-Verwaltungs-System)")]), _c('div', {
    staticClass: "save-setup"
  }, [_c('div', {
    staticClass: "save-setup-choice",
    class: {
      "save-setup-active": _vm.pvs === "charly"
    },
    on: {
      "click": function click($event) {
        _vm.pvs = "charly";
      }
    }
  }, [_c('div', {
    staticClass: "line bold"
  }, [_vm._v("solutio charly")])]), _c('div', {
    staticClass: "save-setup-choice",
    class: {
      "save-setup-active": _vm.pvs === "z1"
    },
    on: {
      "click": function click($event) {
        _vm.pvs = "z1";
      }
    }
  }, [_c('div', {
    staticClass: "line bold"
  }, [_vm._v("CGM Z1 (Pro)")])]), _c('div', {
    staticClass: "save-setup-choice",
    class: {
      "save-setup-active": _vm.pvs === "evident"
    },
    on: {
      "click": function click($event) {
        _vm.pvs = "evident";
      }
    }
  }, [_c('div', {
    staticClass: "line bold"
  }, [_vm._v("EVIDENT")])])])]) : _vm._e(), _c('h3', [_vm._v("Auftraggeber")]), _c('span', {
    staticClass: "mb-2"
  }, [_vm._v("Der Auftraggeber ist ebenfalls der Rechnungsempfänger.")]), _c(VTextField, {
    attrs: {
      "rules": [_vm.rules.required],
      "label": "Name",
      "required": "",
      "hint": "Bei natürlicher Person Nachname, bei juristicher Person oder Personengesellschaft Firmenname eintragen.",
      "persistent-hint": "",
      "data-testid": "praxisDaten.auftraggeber.name"
    },
    model: {
      value: _vm.praxisDaten.auftraggeber.name,
      callback: function callback($$v) {
        _vm.$set(_vm.praxisDaten.auftraggeber, "name", $$v);
      },
      expression: "praxisDaten.auftraggeber.name"
    }
  }), _c(VTextField, {
    attrs: {
      "label": "(Vor-) Name",
      "hint": "Bei natürlicher Person Vorname eintragen. Bei Personengesellschaften oder juristicher Person zusätzlich für Gesellschaftsnamen verwenden oder freilassen.",
      "persistent-hint": "",
      "data-testid": "praxisDaten.auftraggeber.vorname"
    },
    model: {
      value: _vm.praxisDaten.auftraggeber.vorname,
      callback: function callback($$v) {
        _vm.$set(_vm.praxisDaten.auftraggeber, "vorname", $$v);
      },
      expression: "praxisDaten.auftraggeber.vorname"
    }
  }), _c(VTextField, {
    attrs: {
      "rules": [_vm.rules.required, _vm.rules.email],
      "label": "E-Mail",
      "required": "",
      "hint": "Diese E-Mail-Adresse ist die Hauptkorrespondezadresse der Praxis. Die Buchhaltungsadresse für den Empfang der Rechnungen kann später angegeben werden.",
      "persistent-hint": "",
      "data-testid": "praxisDaten.auftraggeber.email"
    },
    model: {
      value: _vm.praxisDaten.auftraggeber.email,
      callback: function callback($$v) {
        _vm.$set(_vm.praxisDaten.auftraggeber, "email", $$v);
      },
      expression: "praxisDaten.auftraggeber.email"
    }
  }), !_vm.isR4cOnboarding ? _c('div', [_c(VTextField, {
    attrs: {
      "rules": [_vm.rules.required],
      "label": "Straße",
      "required": "",
      "persistent-hint": "",
      "hint": ""
    },
    model: {
      value: _vm.praxisDaten.auftraggeber.straße,
      callback: function callback($$v) {
        _vm.$set(_vm.praxisDaten.auftraggeber, "straße", $$v);
      },
      expression: "praxisDaten.auftraggeber.straße"
    }
  }), _c(VTextField, {
    attrs: {
      "rules": [_vm.rules.required],
      "label": "PLZ",
      "required": "",
      "persistent-hint": "",
      "hint": ""
    },
    model: {
      value: _vm.praxisDaten.auftraggeber.plz,
      callback: function callback($$v) {
        _vm.$set(_vm.praxisDaten.auftraggeber, "plz", $$v);
      },
      expression: "praxisDaten.auftraggeber.plz"
    }
  }), _c(VTextField, {
    attrs: {
      "rules": [_vm.rules.required],
      "label": "Stadt",
      "required": "",
      "persistent-hint": "",
      "hint": ""
    },
    model: {
      value: _vm.praxisDaten.auftraggeber.stadt,
      callback: function callback($$v) {
        _vm.$set(_vm.praxisDaten.auftraggeber, "stadt", $$v);
      },
      expression: "praxisDaten.auftraggeber.stadt"
    }
  })], 1) : _vm._e(), _c('h3', {
    staticClass: "secondHeading mt-8"
  }, [_vm._v("Praxis")]), _c('span', {
    staticClass: "mb-2"
  }, [_vm._v("Der Praxisname wird in rose für die eindeutige Kennzeichnung der Praxis genutzt.")]), _c(VTextField, {
    ref: "name",
    attrs: {
      "rules": [_vm.rules.required, _vm.rules.praxisname],
      "label": "Name",
      "required": "",
      "persistent-hint": "",
      "hint": "",
      "data-testid": "praxisDaten.praxis.name"
    },
    model: {
      value: _vm.praxisDaten.praxis.name,
      callback: function callback($$v) {
        _vm.$set(_vm.praxisDaten.praxis, "name", $$v);
      },
      expression: "praxisDaten.praxis.name"
    }
  }), _c(VTextField, {
    attrs: {
      "rules": [_vm.rules.required, _vm.rules.praxisname],
      "label": "Name kurz",
      "required": "",
      "persistent-hint": "",
      "hint": "Kurzform des Praxisnamens, wenn der Platz zur Anzeige des vollen Namens nicht ausreicht.",
      "autofocus": "",
      "data-testid": "praxisDaten.praxis.nameKurz"
    },
    model: {
      value: _vm.praxisDaten.praxis.nameKurz,
      callback: function callback($$v) {
        _vm.$set(_vm.praxisDaten.praxis, "nameKurz", $$v);
      },
      expression: "praxisDaten.praxis.nameKurz"
    }
  }), _c(VTextField, {
    attrs: {
      "rules": [_vm.rules.required],
      "label": "Telefon",
      "required": "",
      "persistent-hint": "",
      "hint": "",
      "data-testid": "praxisDaten.praxis.tel"
    },
    model: {
      value: _vm.praxisDaten.praxis.tel,
      callback: function callback($$v) {
        _vm.$set(_vm.praxisDaten.praxis, "tel", $$v);
      },
      expression: "praxisDaten.praxis.tel"
    }
  }), _vm.isR4cOnboarding ? _c('div', [_c(VTextField, {
    attrs: {
      "rules": [_vm.rules.required],
      "label": "Straße",
      "required": "",
      "persistent-hint": "",
      "hint": ""
    },
    model: {
      value: _vm.praxisDaten.praxis.straße,
      callback: function callback($$v) {
        _vm.$set(_vm.praxisDaten.praxis, "straße", $$v);
      },
      expression: "praxisDaten.praxis.straße"
    }
  }), _c(VTextField, {
    attrs: {
      "rules": [_vm.rules.required],
      "label": "Stadt",
      "required": "",
      "persistent-hint": "",
      "hint": ""
    },
    model: {
      value: _vm.praxisDaten.praxis.stadt,
      callback: function callback($$v) {
        _vm.$set(_vm.praxisDaten.praxis, "stadt", $$v);
      },
      expression: "praxisDaten.praxis.stadt"
    }
  }), _c(VTextField, {
    attrs: {
      "rules": [_vm.rules.required],
      "label": "PLZ",
      "required": "",
      "persistent-hint": "",
      "hint": ""
    },
    model: {
      value: _vm.praxisDaten.praxis.plz,
      callback: function callback($$v) {
        _vm.$set(_vm.praxisDaten.praxis, "plz", $$v);
      },
      expression: "praxisDaten.praxis.plz"
    }
  })], 1) : _vm._e(), !_vm.isR4cOnboarding ? _c('div', [_c(VCheckbox, {
    attrs: {
      "label": "Abweichende Versandadresse"
    },
    model: {
      value: _vm.abweichendeVersandadresse,
      callback: function callback($$v) {
        _vm.abweichendeVersandadresse = $$v;
      },
      expression: "abweichendeVersandadresse"
    }
  }), _vm.abweichendeVersandadresse ? _c(VTextField, {
    attrs: {
      "rules": [_vm.rules.required],
      "label": "Straße",
      "required": ""
    },
    model: {
      value: _vm.praxisDaten.praxis.straße,
      callback: function callback($$v) {
        _vm.$set(_vm.praxisDaten.praxis, "straße", $$v);
      },
      expression: "praxisDaten.praxis.straße"
    }
  }) : _vm._e(), _vm.abweichendeVersandadresse ? _c(VTextField, {
    attrs: {
      "rules": [_vm.rules.required],
      "label": "PLZ",
      "required": ""
    },
    model: {
      value: _vm.praxisDaten.praxis.plz,
      callback: function callback($$v) {
        _vm.$set(_vm.praxisDaten.praxis, "plz", $$v);
      },
      expression: "praxisDaten.praxis.plz"
    }
  }) : _vm._e(), _vm.abweichendeVersandadresse ? _c(VTextField, {
    attrs: {
      "rules": [_vm.rules.required],
      "label": "Stadt",
      "required": ""
    },
    model: {
      value: _vm.praxisDaten.praxis.stadt,
      callback: function callback($$v) {
        _vm.$set(_vm.praxisDaten.praxis, "stadt", $$v);
      },
      expression: "praxisDaten.praxis.stadt"
    }
  }) : _vm._e()], 1) : _vm._e(), _c('h3', {
    staticClass: "secondHeading mt-6 mb-2"
  }, [_vm._v("Empfehlung")]), _c(VBtnToggle, {
    staticClass: "flex-wrap",
    attrs: {
      "mandatory": ""
    },
    model: {
      value: _vm.praxisDaten.empfehlung,
      callback: function callback($$v) {
        _vm.$set(_vm.praxisDaten, "empfehlung", $$v);
      },
      expression: "praxisDaten.empfehlung"
    }
  }, _vm._l(_vm.empfehlungsAuswahl, function (choice) {
    return _c(VBtn, {
      key: choice,
      attrs: {
        "value": choice,
        "small": ""
      }
    }, [_vm._v(_vm._s(choice))]);
  }), 1), _vm.praxisDaten.empfehlung == "Sonstiges" ? _c(VTextField, {
    staticClass: "ml-6",
    attrs: {
      "label": "Sonstiges"
    },
    model: {
      value: _vm.empfehlungSonstiges,
      callback: function callback($$v) {
        _vm.empfehlungSonstiges = $$v;
      },
      expression: "empfehlungSonstiges"
    }
  }) : _vm._e()], 1)], 1), _c(VDivider, {
    staticClass: "mt-12"
  }), _c(VCardActions, [_c('div', {
    staticClass: "flex-grow-1"
  }), _c(VBtn, {
    attrs: {
      "color": "primary",
      "disabled": !_vm.viewIsValid,
      "data-testid": "next"
    },
    on: {
      "click": _vm.next
    }
  }, [_vm._v("Weiter")])], 1)], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };