import { isDev } from './config';

const rulesInternal = {
  required: (value: any) => !!value || 'Benötigt.',
  email: (value: string) => {
    // empty field is valid
    if (!value) {
      return true;
    }
    const pattern =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return pattern.test(value) || 'Keine gültige E-Mail.';
  },
  emailDelimited: (value: string) => {
    const pattern = /^([\w+-.%]+@[\w.-]+\.[A-Za-z]{2,})(,\s[\w+-.%]+@[\w.-]+\.[A-Za-z]{2,})*$/;
    return value
      ? pattern.test(value) ||
          'Keine gültige E-Mail-Liste. Bitte in der Form: "mail@domain.de, mail2@domain.de" eingeben.'
      : true;
  },
  regex: (value: string) => {
    try {
      new RegExp(value);
      return true;
    } catch (e) {
      return 'Kein gültiger Regex! Bitte korrigieren Sie Ihre Eingabe.';
    }
  },
  ip: (value: string) => {
    const pattern = /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    const hostnamePattern =
      /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9-]*[A-Za-z0-9])$/;
    return pattern.test(value) || hostnamePattern.test(value) || 'Keine gültige IP-Adresse oder Hostname.';
  },
  praxisname: (value: string) => {
    const badNames = [
      'praxis',
      'zahnarzt',
      'zahnärzte',
      'zahnaerzte',
      'zahngesundheit',
      'praxis für zahnheilkunde',
      'zahnärztliche gemeinschaftspraxis',
      'gemeinschaftspraxis',
    ];
    return !badNames.includes(value) || 'Bitte nutze einen besseren Praxisnamen';
  },
};

// disable some validation on dev
if (isDev) {
  rulesInternal.email = () => true;
}

export const rules = rulesInternal;
