import { ISupportCustomerSearchResult, ISupportTicket } from '../../../../types';
import { RoseApi } from '../apiTypes';

export function createSupportApi(api: RoseApi) {
  return {
    searchCustomers: (query: string) =>
      api.metrics.get<ISupportCustomerSearchResult>(`/api/support/customer/search`, { query: { query } }),
    createTicket: (ticket: ISupportTicket) => api.metrics.post<string>(`/api/support/ticket`, ticket),
  };
}
